import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark }) => {
  return (
    <>
      <section
      id='servicios'
        className={`feature-section ptb-120 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              {cardDark ? (
                <SectionTitle
                  subtitle='Que ofrecemos'
                  title='Los mejores servicios hacen crecer el valor de su negocio'
                  description='Contar con información actualizada y confiable es fundamental para la toma de decisiones en su organización.'
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  subtitle='Services'
                  title='Best Services Grow Your Business Value'
                  description='Globally actualize cost effective with resource maximizing
                  leadership skills.'
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`shadow-sm highlight-card rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-accent
                      mb-32
                      text-white
                    '
                  >
                    <i className='fal fa-code icon-sm '></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Aplicaciones a medida</h3>
                    <p>
                    Creamos soluciones innovadoras y personalizadas que se adaptan perfectamente a su organización.
                    </p>
                    <h6 className='mt-4'>Aplicaciones...</h6>
                    <ul className='list-unstyled mb-0'>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-white'
                          }`}
                        ></i>
                        Reactivas
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-white'
                          }`}
                        ></i>
                        Escalables
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-white'
                          }`}
                        ></i>
                        En tiempo real
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-white'
                          }`}
                        ></i>
                        Robustas
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-white'
                          }`}
                        ></i>
                        Seguras
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-white'
                          }`}
                        ></i>
                        En la nube (cloud) o de infraestructura local (self hosted)
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        SaaS (Software as a Service) u On Premise (Software tradicional)
                      </li>
                    </ul>
                  </div>
                  
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      mb-32
                      bg-black text-white
                    '
                  >
                    <i className='fal fa-computer-mouse icon-sm '></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Aplicaciones web</h3>
                    <p className='mb-0'>
                    Desarrollamos aplicaciones web a medida, combinando funcionalidad, estética y experiencia de usuario optimizada.
                    </p>
                  </div>
                  
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      mb-32
                      text-white
                      bg-soft-black
                    '
                  >
                    <i className='fal fa-mobile-phone icon-sm '></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Aplicaciones Móviles</h3>
                    <p className='mb-0'>
                    Creamos aplicaciones móviles intuitivas y de alto rendimiento para iOS y Android. Con un enfoque en la usabilidad y el diseño, nuestras aplicaciones móviles ofrecen una experiencia de usuario excepcional, manteniendo a su público conectado y comprometido.
                    </p>
                  </div>
                  
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-chart-scatter-3d icon-sm text-white'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Data Science</h3>
                    <p className='mb-0'>
                    Ofrecemos servicios de ciencia de datos que transforman grandes volúmenes de datos en insights accionables. Nuestro equipo de expertos utiliza técnicas avanzadas de análisis y modelado para ayudarle a tomar decisiones basadas en datos, optimizar procesos y predecir tendencias futuras.
                    </p>
                  </div>
                  
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  >
                    <i className='fal fa-cloud icon-sm text-white'></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>Cloud computing</h3>
                    <p className='mb-0'>
                    Proveemos soluciones de computación en la nube que garantizan flexibilidad, escalabilidad y seguridad. Desde la infraestructura de nube hasta las plataformas y aplicaciones basadas en la nube, facilitamos una transición suave y eficiente, permitiendo a su empresa ser más ágil y competitiva.
                    </p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
