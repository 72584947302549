import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';
import { Link } from "react-router-dom";
import { scrollTo } from '../../helpers/navigation';


const Hero = () => {
  return (
    <>
      <section
      id='inicio'
        className='hero-section ptb-120 bg-dark text-white'
        style={{
          //background:
           // "url('assets/img/hero-dot-bg.png') top right no-repeat",
          //background:
          //  "url('assets/img/Map.png') center center no-repeat",

          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg') bottom center no-repeat",
            backgroundSize: 'contain',
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                  className='hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0'
                  data-aos='fade-right'
              >
                <HeroTitle
                    title=' Sus ideas, nuestras soluciones'
                    desc='Convertimos sus conceptos en aplicaciones innovadoras, seguras y escalables para usted o su organización'
                />
                <ul className="nav subscribe-feature-list mt-3 justify-content-lg-start justify-content-md-center">
                  <li className="nav-item">
                      <span className="ms-0">
                        <i className="far fa-check-circle text-primary me-2"></i>
                        Aplicaciones web
                      </span>
                  </li>
                  <li className="nav-item">
                      <span>
                        <i className="far fa-check-circle text-primary me-2"></i>
                        Aplicaciones móviles
                      </span>
                  </li>
                  <li className="nav-item">
                      <span>
                        <i className="far fa-check-circle text-primary me-2"></i>
                        Ciencia de  datos
                      </span>
                  </li>
                </ul>
                <div
                    className='hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-none d-md-block d-lg-block d-xl-block'>
                  <form
                      id='subscribe-form'
                      name='email-form'
                      className='hero-subscribe-form d-block d-lg-flex d-md-flex'
                  >
                    <Link
                        onClick={() => scrollTo('contacto')}
                        className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                        to={"#contacto"}>Cuéntenos su idea</Link>
                  </form>

                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 mt-5">
              <div
                  className="hero-img-wrap position-relative"
                  data-aos="fade-left"
              >
                <div className='hero-screen-wrap'>

                  <div className='mac-screen'>
                    <img
                        src='assets/img/screen/hero-screen-3.svg'
                        //src='assets/img/dashkit.png'
                        alt='hero '
                        className='position-relative img-fluid rounded-custom'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
