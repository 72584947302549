import React, { useState } from 'react';
import  { sendMail } from '../../helpers/sendMail';

const ContactsForm = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [position, setPosition] = useState('');
  const [projectDetails, setProjectDetails] = useState('');
  const [showAlert, setshowAlert] = useState(false);
  const [showSuccess, setshowSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Evita el comportamiento por defecto del formulario
    //Valido que todos los campos estén llenos
    if (name === '' || email === '' || company === '' || position === '' || projectDetails === '') {
      setshowAlert(true);
      setshowSuccess(false);
      return;
    }else{
      setshowAlert(false);
      //Enviar correo usando Brevo
      const resp = await sendMail(email, name, company, position, projectDetails);
      console.log(resp);
      if(resp){
      //limpio los campos
        setName('');
        setEmail('');
        setCompany('');
        setPosition('');
        setProjectDetails('');
        setshowSuccess(true);

      }
    }
  };
  return (
    <>
      <div className='col-xl-5 col-lg-7 col-md-12 order-0 order-lg-1'>
        <div className='register-wrap p-5 bg-white shadow rounded-custom mt-5 mt-lg-0 mt-xl-0'>
          <h3 className='fw-medium h4'>
            Cuéntenos su proyecto y nos pondremos en contacto lo más pronto posible
          </h3>

          <form action='#' className='mt-4 register-form' onSubmit={handleSubmit} >
            <div className='row'>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Nombre'
                    aria-label='name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-sm-6 '>
                <div className='input-group mb-3'>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='Correo'
                    aria-label='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Empresa'
                    aria-label='company-website'
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='input-group mb-3'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='Cargo'
                    aria-label='work-Email'
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='input-group mb-3'>
                  <textarea
                    className='form-control'
                    placeholder='Cuéntanos sobre tu proyecto'
                    style={{ height: '120px' }}
                    value={projectDetails}
                    onChange={(e) => setProjectDetails(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className='col-12'>
                {showAlert && <div className='alert alert-danger' role='alert'>
               Por favor complete todos los campos</div>}
              
               </div>
              <div className='col-12'>
                <button
                  type='submit'
                  className='btn btn-primary mt-4 d-block w-100'
                >
                  Enviar
                </button>
              </div>
              <div className='col-12 mt-4'>
              {showSuccess && <div className='alert alert-success' role='alert'>
               Muchas gracias, pronto estaremos en contacto</div>}
               </div>
              <div className='col-12 mt-5'>
                <p className='text-primary'><i className='fas fa-envelope'></i> También puede escribirnos a <a href='mailto:proyectos@koding.cl'>proyectos@koding.cl</a></p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactsForm;
