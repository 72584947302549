import React from 'react';
import SectionTitle from '../common/SectionTitle';
import ContactsForm from './ContactsForm';

const ContactFormThree = () => {
  return (
    <>
      <section className='contact-us pt-5 pb-5 position-relative overflow-hidden' id="contacto">
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-xl-5 col-lg-5 col-md-12'>
              <SectionTitle
                subtitle='Estamos para ayudar'
                title='¡Contáctenos!'
              />
             <div className='contact-us-img '>
                <img
                  src='assets/img/contact-us-img-2.svg'
                  alt='contact us'
                  className='img-fluid'
                />
              </div>
            </div>
            <ContactsForm />
          </div>
        </div>
        <div
          className='bg-dark position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1'
        >
        </div>
      </section>
    </>
  );
};
export default ContactFormThree;
