import React from 'react';
import { Link } from 'react-router-dom';
import { scrollTo } from "../../helpers/navigation";


const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className='footer-section'>
        <div
          className={`footer-top ${footerLight ? 'footer-light' : 'bg-dark'} ${
            footerGradient ? 'bg-gradient' : ''
          }  text-white ptb-120`}
          style={style}
        >
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-8 col-lg-4 mb-md-4 mb-lg-0'>
                <div className='footer-single-col'>
                  <div className='footer-single-col mb-4'>
                    <img
                      src='assets/img/logo-white.png'
                      alt='logo'
                      className='img-fluid logo-white'
                    />
                    <img
                      src='assets/img/logo-color.png'
                      alt='logo'
                      className='img-fluid logo-color'
                    />
                  </div>
                  
                </div>
              </div>
              <div className='col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0 '>
                <div className='row'>
                  <div className='col-md-12 col-lg-12 mt-12 mt-md-0 mt-lg-0'>
                    <div className='footer-single-col'>
                      <h3>Vuelve a ver</h3>
                      <ul className='list-unstyled footer-nav-list mb-lg-0 list-inline'>
                      <li class="list-inline-item">
                  <Link
                    className="nav-link "
                    role="button"
                    onClick={() => scrollTo("inicio")}
                  >
                    Inicio
                  </Link>
                 
                </li>
                <li class="list-inline-item">
                  <Link className="nav-link"  onClick={() => scrollTo("servicios")}>
                    Servicios
                  </Link>
                </li>
                <li class="list-inline-item">
                  <Link className="nav-link"  onClick={() => scrollTo("proceso")}>
                    Proceso
                  </Link>
                </li>
                <li class="list-inline-item">
                  <Link className="nav-link"  onClick={() => scrollTo("equipo")}>
                    Equipo
                  </Link>
                </li>
                <li class="list-inline-item">
                  <Link className="nav-link"  onClick={() => scrollTo("contacto")}>
                    Contacto
                  </Link>
                </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? 'footer-light' : 'bg-dark'
          } ${footerGradient ? 'bg-gradient' : ''} text-white py-4`}
        >
          <div className='container'>
            <div className='row justify-content-between align-items-center'>
              <div className='col-md-7 col-lg-7'>
                <div className='copyright-text'>
                  <p className='mb-lg-0 mb-md-0'>
                  Creado con <i className='fas fa-heart '></i> por{' '} nostros
                    
                  </p>
                </div>
              </div>
              <div className='col-md-4 col-lg-4'>
                <div className='footer-single-col text-start text-lg-end text-md-end'>
                  <ul className='list-unstyled list-inline footer-social-list mb-0'>
                   
                    <li className='list-inline-item'>
                      <a href='https://www.instagram.com/koding.cl/' target='_blank' rel="noreferrer">
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                   
                    <li className='list-inline-item'>
                      <a href='https://github.com/koding-dev' rel="noreferrer" target='_blank'>
                        <i className='fab fa-github'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
