import React from 'react';

const Team = () => {
  return (
    <>
      <section id='equipo' className='team-section pt-60 pb-120 bg-dark'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7 col-md-12'>
              <div className='section-heading text-center'>
                <h5 className='h6 text-primary'>Nuestro equipo</h5>
                <h2>Las personas detrás de KODING</h2>
                <p>
                  Somos un grupo de profesionales apasionados por la tecnología y con más de 10 años de experiencia en el desarrollo de software y soluciones de datos.
                </p>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6 offset-lg-2'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/team-1.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list team-social-style-2 list-inline mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/in/claudio-alvarez/' target='_blank' rel='noreferrer'>
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://github.com/calvarezp' target='_blank' rel='noreferrer'>
                        <i className='fab fa-github'></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='team-info mt-4 '>
                  <h5 className='h6 mb-1'>Claudio Alvarez Paredes</h5>
                  <p className='text-muted small mb-0'>CTO</p>
                  <p className='text-muted small mb-0'>Software Engineer</p>
                  <p className='text-muted small mb-0'>Full Stack Developer</p>
                  <p className='text-muted small mb-0'>Infrastructure Specialist</p>
                </div>
              </div>
            </div>
            {/*<div className='col-lg-4 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/team-2.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list team-social-style-2 list-inline mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/in/fabiola-pinto-escobar-212959157/' target='_blank' rel='noreferrer'>
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='team-info mt-4'>
                  <h5 className='h6 mb-1'>Fabiola Pinto Escobar</h5>
                  <p className='text-muted small mb-0'>Data Science Engineer</p>
                  <p className='text-muted small mb-0'>Magíster (c) en Ciencias de la Ingeniería</p>
                  <p className='text-muted small mb-0'>Mención Recursos y Medio Ambiente Hídrico</p>
                  <p className='text-muted small mb-0'>MSc Data Scientist</p>
                  <p className='text-muted small mb-0'>Ingeniera Civil</p>
                </div>
              </div>
            </div>*/}
            <div className='col-lg-4 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/team-3.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list team-social-style-2 list-inline mb-0'>
                    <li className='list-inline-item'>
                      <a href='https://www.linkedin.com/in/eduardo-pe%C3%B1a-fritz-83490891/' target='_blank' rel="noreferrer">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <a href='https://github.com/edofritz' target='_blank' rel="noreferrer">
                        <i className='fab fa-github'></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='team-info mt-4 '>
                  <h5 className='h6 mb-1'>Eduardo Peña Fritz</h5>
                  <p className='text-muted small mb-0'>CEO</p>
                  <p className='text-muted small mb-0'>Software Engineer</p>
                  <p className='text-muted small mb-0'>Senior Developer</p>
                  <p className='text-muted small mb-0'>MSc Data Science</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
