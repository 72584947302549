import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgThree = ({paddingTop}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-120':'pt-60 pb-120'} `}>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <SectionTitle
                subtitle='Cómo lo hacemos'
                title='Las soluciones poderosas que su organización necesita'
                description='Fusionamos tecnología avanzada y comprensión única de sus necesidades, entregando soluciones personalizadas y potentes que impulsan su organización hacia el futuro.'
              />
              <div>
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className='fas fa-code text-white'></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'><b>Aplicaciones</b> a medida</h3>
                      <p>
                      Materializamos sus ideas utilizando la última tecnología, asegurando soluciones innovadoras y personalizadas que se adaptan perfectamente a sus necesidades.
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box  bg-secondary rounded me-4'>
                      <i className='fas fa-fingerprint '></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'><b>Ciencia de Datos</b> para decisiones inteligentes</h3>
                      <p>
                        Generamos modelos de aprendizaje automático adaptados a la información generada por su organización.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
               {/* <ul className='img-overlay-list list-unstyled position-absolute'>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='50'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Create a Free Account</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='100'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Install Our Tracking Pixel</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='150'
                  >
                    <i className='fas fa-check bg-primary text-white rounded-circle'></i>
                    <h6 className='mb-0'>Start Tracking your Website</h6>
                  </li>
  </ul>*/}
                <img
                  src='assets/img/feature-hero-img-2.svg'
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgThree;
