//Enviar correo usando Brevo

const api_key = process.env.REACT_APP_BREVO_API_KEY
const sendMail = async (email, name, company, position, message) => {
    console.log(api_key)
    const res = await fetch('https://api.brevo.com/v3/smtp/email', {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'api-key': api_key
        },
        body: JSON.stringify({
            templateId: 1,
            sender:{
                name: name,
                email: email
            },
            to:[{
                email: 'proyectos@koding.cl',
                name: 'Proyectos'
            }],
            params:{
                company: company,
                position: position,
                message: message,
                name:name
            },
        })})

        return res.status === 201;


    }     
    
    export { sendMail}