import React from 'react';

const WorkProcessFour = () => {
  return (
    <>
      <section className='work-process ptb-120' id='proceso'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <div className='section-heading text-center'>
                <h4 className='h5 text-primary'>Nuestro Proceso</h4>
                <h2>Seguimos un <b>flujo</b> de trabajo</h2>
                <p>
                Nuestro proceso de desarrollo se basa en metodologías ágiles, permitiendo entregas continuas y adaptativas que aseguran una perfecta alineación con sus expectativas. Valoramos enormemente la retroalimentación del cliente en cada etapa, lo que nos permite realizar ajustes precisos y mejorar constantemente el producto final.
                </p>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-2 mb-lg-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>1</span>
                </div>
                <h3 className='h5'>Investigación</h3>
                <p className='mb-0'>
                  Analizamos el problema y las necesidades de su organización.
                </p>
              </div>
            </div>
            <div className='dots-line first'></div>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-3 rounded-custom shadow-hover mb-2 mb-lg-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>2</span>
                </div>
                <h3 className='h5'>Diseño</h3>
                <p className='mb-0'>
                  Creamos en conjunto una propuesta que resuelva la problemática.
                </p>
              </div>
            </div>
            <div className='dots-line first'></div>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-2 mb-lg-0 mb-md-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>3</span>
                </div>
                <h3 className='h5'>Desarrollo</h3>
                <p className='mb-0'>
                  Construimos la aplicación y entregamos MVP de forma recurrente.
                </p>
              </div>
            </div>
            <div className='dots-line first'></div>
            <div className='col-md-6 col-lg-3'>
              <div className='process-card text-center px-4 py-lg-5 py-4 rounded-custom shadow-hover mb-0 mb-lg-0 mb-md-0'>
                <div className='process-icon border border-light bg-custom-light rounded-custom p-3'>
                  <span className='h2 mb-0 text-primary fw-bold'>4</span>
                </div>
                <h3 className='h5'>Validación y Entrega</h3>
                <p className='mb-0'>
                  Desplegamos el resultado en producción.
                </p>
                <p className='mb-0'>
                  Comienza la marcha blanca (si se requiere) y el soporte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcessFour;
