import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import Hero from './Hero';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgThree from '../../components/features/FeatureImgThree';
import Customer from '../../components/customer/Customer';
import WorkProcessFour from '../../components/work-process/WorkProcessFour';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureTwo from '../../components/features/FeatureTwo';
import Team from '../../components/team/Team';
import CallToAction from '../../components/cta/CtaTwo'
import Contact from '../../pages/Contact';

const Home = () => {
  return (
    <Layout>
      <PageMeta title='Koding - Sus Ideas, Nuestras Soluciones' />
      <Navbar navDark posAbsolute />
      <Hero />
      <Customer gray /> 
      {/* <<PromoThree />*/}
      <FeatureImgThree  />
      <FeatureTwo cardDark />
      <WorkProcessFour />
      <CallToAction/>
      <FeatureImgEight />
      <Team />
      <Contact/>
      <FooterOne  />
    </Layout>
  );
};

export default Home;
