import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import SectionTitle from '../common/SectionTitle';
import { scrollTo } from '../../helpers/navigation';

const CtaTwo = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <section className='cta-subscribe  ptb-120 position-relative overflow-hidden bg-dark'>
        <div className='container'>
          <div className='row '>
            <div className='col-lg-12 col-md-12'>
              <div className='subscribe-info-wrap text-center position-relative z-2'>
                <SectionTitle
                  subtitle="Ponte en contacto con nosotros"
                  title='Comience su proyecto en Koding'
                  description='Estamos listos para ayudarle a llevar su idea al siguiente nivel. Contáctenos para obtener más información sobre nuestros servicios.'
                  dark
                />
                <div className='form-block-banner mw-60 m-auto mt-5'>
                  <Link  className='btn btn-primary' onClick={() => scrollTo("contacto")}>
                    Cuéntenos su idea
                  </Link>
                </div>
              </div>
            </div>
          </div>
          
          
        </div>
      </section>
    </>
  );
};

export default CtaTwo;
