import React from 'react';
import ContactForm from '../components/contact/ContactFormThree';

const Contact = () => {
  return (
      <>
      <ContactForm />
      </>
  );
};

export default Contact;
