import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Home from "../themes/index6/Home";


function index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
